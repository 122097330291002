.vacancy{
    background-color: #eee;
}
.rowPanelHolder{
    display: flex;
    flex-direction: column;
}
.rowPanelHolder>.rowPanel:first-child{
    background-color: transparent;
    font-size: 11px;
    font-weight: normal;
    padding-bottom: 0px;
    color: rgba(0, 0, 0, 0.367);
}
.rowPanelHolder>.rowPanel:first-child>div>p{
    margin-bottom: 2px;
}
.rowPanel{
    display: flex;
    width: 850px;
    align-items: center;
    background-color: #fff;
    padding: 8px 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 6px;
}
.rowPanel>div{
    flex: 1;
}
.rowPanel>div>h3{
    font-size: 14px;
}
.rowPanel>div:nth-child(2){
    display: flex;
    justify-content: center;
}
.rowPanel>div:nth-child(2)>p{
    font-size: 14px;
    text-align: center;
    margin: 5px 0px;
}
.rowPanel>div:last-child{
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
}


.emptyRowPanel{
    font-size: 50px;
    padding: 0px inherit;
}