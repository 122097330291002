.servicesPageEdit{
    padding: 30px 50px;
}
.emptyItemPanel{
    display: flex;
    justify-content: center!important;
    align-items: center;
    border: 1px solid #08A0F7;
    font-size: 21px!important;
}
.emptyItemPanel:hover{
    border: 3px solid #08A0F7!important;
}
.servicePanelControls{
    display: flex;

}
.servicePanelControls>button{
    flex: 1;
    margin-top: 20px;
    border: none;
    color: white;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: normal;
    background-color: #EB5757;
    cursor: pointer;
}
.servicePanelControls>button:hover{
    opacity: 0.8;
}