.solutionEdit{
    padding: 30px 50px;
}
.solutionEdit>p{
    font-style: italic;
    font-size: 14px;
    margin-bottom: 0px;
}
.servicesEdit{
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(6, 74, 48, 0.07);
    background-color: white;
    margin: 20px 0px;
    border-radius: 10px;
}
.servicesEdit>div{
    display: flex;
    margin-top: 50px;
}
.servicesEdit>div>div{
    flex: 1;
}
.servicesEdit>div>div>h1{
    font-size: 60px;
    font-weight: normal;
    margin: 0px;
    max-width: 85%;
}
.servicesEdit>div>div>p{
    margin: 0px;
    margin-top: 5px;
}
.servicesEdit>div>div>h3{
    margin-top: 0px;
}
.servicesEditImage>div{
    margin-right: 50px;
    border-radius: 4px;
}
.servicesEditImage>div>img{
    width: 100%;
    border-radius: 4px;
}
.solutionEditServices{
    list-style: none;
    padding: 0px;
    margin: 10px 0px;
}
.solutionEditServices>li{
    margin-bottom: 15px;
    font-size: 18px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.087);
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;

}