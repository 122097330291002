.loginContainer{
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.loginForm{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 100vh;
    background-color: white;
    width: 500px;
}
.loginForm>div:first-child{
    flex: 1;
    padding: 0px 50px;
    margin-top: 100px;
}
.loginForm>div>h2{
    font-size: 32px;
    margin-bottom: 5px;
}
.loginForm>div>span{
    margin-top: 70px;
    font-size: 14px;
}
.loginForm>div:last-child{
    flex: 2;
}
.loginForm>div>form{
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
}
.loginForm>div>form>div:first-child>div{
    margin-bottom: 28px;
    width: 100%;
}
.loginForm>div>form>div:first-child>div>input{
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding-left: 15px;
    border: none;
    outline: none;
    background: #FFFFFF;
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.118);
    border-radius: 6px;
    border: 1px solid #F4F4F4;

}
.loginForm>div>form>div:last-child>button{
    border: none;
    background: #091934;
    box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.118);
    border-radius: 6px;
    width: 100%;
    height: 60px;
    color: #f9f9f9;
    cursor: pointer;
}
.loginForm>div>form>div:last-child>button:hover{
    background: #091934d7;

}