.candidates{
    padding: 30px 50px;
}
.changePage{
    display: flex;
    width: 100%;
    justify-content: center;
}
.changePage>button{
    margin: 10px 5px;
    padding: 8px 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #08A0F7;
    border-radius: 5px;
    border: none;
    color: white;
}