@font-face {
  font-family: montserrat;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: montserrat;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: montserrat;
  src: url(./assets/fonts/Montserrat-Light.ttf);
  font-weight: 300;
}

body {
  margin: 0;
  font-family: montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navDebug{
  margin-left: 300px;
}

.displayNone{
  display: none!important;
}

.headerLayout{
  padding: 10px 0px;
  padding-bottom: 50px;
  padding-left: 50px;
}
.headerLayout>h1{
  margin-left: 0px;
}

.panelHolder{
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.panelHolder>.Panel:first-child{
  margin-left: 0px;
}

/* regular Panel */
.Panel{
  width: 400px;
  /* min-height: 450px; */
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(6, 74, 48, 0.07);
}
.Panel>video{
  width: 100%;
  border-radius: 4px;
}
.Panel>img{
  width: 100%;
  border-radius: 4px;
}
.Panel>p{
  color: black!important;
  font-size: 14px;
}
.panelControls{
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
}
.panelControls>button{
  margin: 0px 2px;
  border: none;
  outline: none;
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #08A0F7;
  color: white;
}
.panelControls>button:hover{
  opacity: 0.9;
}
.panelControls>button:last-child{
  background-color: #EB5757;
  color: white;
}


/* Small panels with image */

.smallPanel{
  width: 370px;
  overflow: hidden;
  min-height: 220px;
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

}
.smallPanel>img{
  width: 100%;
  border-radius: 4px;
}
.smallPanel>h3{
  margin: 5px 0px;
  font-size: 14px;
}
.smallPanel>p{
  color: black!important;
  font-size: 14px;
}
.smallPanelControls{
  display: flex;
}
.smallPanelControls>button{
  margin: 0px 2px;
  border: none;
  outline: none;
  padding: 10px 0px;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  cursor: pointer;
  background-color: #08A0F7;
  color: white;
}
.smallPanelControls>button:hover{
  opacity: 0.9;
}
.smallPanelControls>button:last-child{
  background-color: #EB5757;
  color: white;
}




.emptyPanel{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  border: 3px solid transparent;
  transition: 0.25s;
  color: #08A0F7;
  cursor: pointer;
}
.emptyPanel:hover{
  border: 3px solid #08A0F7;
  transition: 0.25s;
}


/* Hotfix */

.panelImgHolder{
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex: 2;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.panelImgHolder>img{
  width: 70%;
  /* height: 100%; */
}



table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td>button{
  padding: 2px;
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: #dddddd;
}


/*  */
.changeNotes{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 50px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(6, 74, 48, 0.07);
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 0px;
}
.changeNotes>.flexBox{
  display: flex;
}
.changeNotes>.flexBox>div{
  margin-right: 40px;
}
.changeNotesCheckbox{
  width: initial!important;
}
.changeNotes>div>h4{
  margin-bottom: 10px;
}
.changeNotes>div>p{
  font-size: 14px;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 0px;
}
.changeNotes>div>input{
  width: 100%;
  padding: 10px 5px;
  box-sizing: border-box;
  font-size: 16px;
}
.changeNotes>div>ul{
  padding: 0px;
  padding-left: 20px; 
  list-style: none;
}
.changeNotes>div>ul>li{
  display: flex;
  margin-bottom: 15px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.changeNotes>div>ul>li>input{
  padding: 3px 5px;
  width: 100%;
}
.changeNotes>div>ul>li::before{
  content: "";
  position: absolute;
  right: 101%;
  border-radius: 1.5px;
  width: 5px;
  height: 5px;
  background-color: black;
}
.changeNotes>div>textarea{
  width: 100%;
  resize: none;
  padding: 10px 5px;
  font-size: 16px;
  min-height: 180px;
  box-sizing: border-box;
}
.changeNotes>button{
  border: none;
  color: white;
  padding: 15px 0px;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  background-color: #08A0F7;
  margin-top: 15px;
}
.changeNotes>button:hover{
  background-color: #089ff7da;
}


/* Edit */
.partnersEdit{
  padding: 30px 50px;
  
}
.panelEdit{
  display: flex;
  justify-content: center;
}
.panelEdit>.smallPanel{
  box-shadow: 0px 0px 20px rgba(6, 74, 48, 0.07);
  margin-bottom: 15px;

}


.goBackButton{
  color: #08A0F7;
  border: none;
  background-color: transparent;
  margin-bottom: 15px;
  font-size: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
}
.goBackButton:hover{
  background-color: #41b9ff1a;
}
.goBackButton>svg{
  margin-right: 10px;
}

.editButtons{
  display: flex;
  margin-left: 15px;
}
.editButtons>button{
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  padding: 2px 5px;
  box-sizing: border-box;
  font-size: 16px;
  width: 30px;
  font-weight: bold;
  cursor: pointer;
  background-color: #F93E3E;
  color: white;
}
.editButtons>button:hover{
  opacity: 0.9;
}
.editButtons>button>svg{
  width: 20px;
  fill: white;
}
.editButtons>button:first-child{
  background-color: #cc9b31;
}