.changeNotesModal{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    width: 100%;
    height: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}
.changeNotesModal>.modal{
    background-color: white;
    box-shadow: 0px 0px 20px rgba(6, 74, 48, 0.07);
    width: 40%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    z-index: 21;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
}
.changeNotesModalAlpha{
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.675);
    width: 100%;
    height: 100%;
    position: absolute;
}
.modal>div>h4{
    margin-bottom: 10px;
    font-size: 18px;
}
.modal>div>input{
    width: 100%;
    margin: 10px 0px;
    margin-top: 0px;
    box-sizing: border-box;
    outline: none;
}
.modal>h1{
    margin-bottom: 5px;
}
.modal>button{
    border: none;
    color: white;
    padding: 5px 0px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    background-color: #08A0F7;
    margin-top: 10px;
}