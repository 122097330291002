.newsEdit{
    padding-left: 50px;
    padding-top: 40px;
    padding-right: 50px;
}

.newsCompanyLogo{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    position: relative;
}
 .newsCompanyLogo::after{
    content: " ";
    background-color: #1c355e52;
    width: calc(100% + 60px);
    left: -30px;
    height: 1px;
    position: absolute;
    bottom: 0px;   
}
 .newsCompanyLogo>div>h2{
    font-size: 24px;
    margin-bottom: 5px;
    margin-top: 0px;
}
 .newsCompanyLogo>div>span{
    font-size: 14px;
}
 .newsPanelHolder{
    width: 100%;
    position: relative;
}
.newsPanel{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(6, 74, 48, 0.07);
    border-radius: 10px;
    padding: 30px;
    height: 250px;
    margin-bottom: 20px;
}
.newsPanel>div>p{
    margin-right: 60px;
}
.newsLogo{
    width: 54px;
    height: 54px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c4c4c4;
    margin-right: 21px;
}
.newsLogo>img{
    width: 80%
}

