.sectorEdit{
    padding: 30px 50px;
}
.sectorEdit>p{
    margin: 0px;
    margin-bottom: -20px;
    font-style: italic;
    font-size: 14px;
}
.dropButton{
    background-color: #EB5757;
    border: none;
    padding: 15px;
    border-radius: 4px;
    color: #fff;
    margin-top: 10px;
}

.sectorPreview{
    display: flex;
    margin: 30px 0px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    gap: 15px;
    box-shadow: 0px 0px 20px rgba(6, 74, 48, 0.07);
}
.sectorPreview>div{
    flex: 1;
}
.sectorPreview>div>h1{
    font-size: 60px;
    font-weight: normal;
}
.sectorPreview>div:first-child{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sectorPreview>div:first-child>ul>li{
    margin-bottom: 20px;
    color: #FFF;
    display: flex;
    align-items: center;
}
.sectorPreview>div>img{
    height: 100%;
    border-radius: 4px;
}
.sectorPreview>div:last-child{
    overflow: hidden;
    height: 800px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
}

.solutionCreateButton{
    color: #08A0F7!important;
    font-size: 18px;
    cursor: pointer;
}
.solutionCreateButton:hover{
    text-decoration: underline;
}
.sectorEditSolutions{
    justify-content: space-between;
}