.navbar{
    width: 300px;
    box-sizing: border-box;
    box-shadow: 15px 0px 80px -20px rgba(32, 56, 85, 0.1);
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
}
.navLogo{
    display: flex;
    justify-content: center;
    padding: 25px 0px;
    position: relative;
}
.navLogo::after{
    content: " ";
    position: absolute;
    bottom: 0px;
    background-color: #EBF0FF;
    height: 1.5px;
    width: 100%;
}
.navList{
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
}
.navUser{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navUser>div>h3{
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
}
.navUser>div>span{
    font-size: 14px;
    color: rgba(116, 116, 116, 0.893);
}
.navUser>div:last-child{
    cursor: pointer;
}
.navUser>div>svg{
    padding: 5px;
    border-radius: 5px;
    background-color: #EBF0FF;
}
.navUser>div>svg:hover{
    background-color: #e0e6fa;
}

.navRoutes{
    padding: 30px 0px 0px;
}
.navRoutes>span{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.652);
}
.navRoutes>ul{
    margin-top: 5px;
    margin-bottom: 30px;
    list-style: none;
    padding: 0px;
}
.navRoutes>ul>li{
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navRoutes>ul>li>svg{
    opacity: 0;
}
.navRoutes>ul>li:hover{
    background-color: #EBF0FF;
}
.navRoutes>ul>li:hover>svg{
    opacity: 1;
}